<template>
    <b-form @submit.prevent="onSubmit">
        <form-input
            :label="$t('externalAcquirers.externalAcquirersTable.name')"
            v-model="name"
            :validation="$v.name"
        />

        <form-input
            :label="$t('externalAcquirers.externalAcquirersTable.slug')"
            v-model="slug"
            :validation="$v.slug"
        />

        <div class="form-actions mt-4">
            <b-btn
                variant="primary"
                type="submit"
                class="mr-2"
            >
                {{ $t('app.submit') }}
            </b-btn>

            <b-btn
                variant="secondary"
                @click.prevent="onCancel"
            >
                {{$t('app.cancel')}}
            </b-btn>
        </div>
    </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
    name: 'ExternalAcquirierForm',
    props: {
        defaults: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data () {
        return {
            id: this.defaults._id || null,
            name: this.defaults.name || null,
            slug: this.defaults.slug || null
        }
    },
    watch: {
        defaults () {
            this.$nextTick(() => {
                this.id = this.defaults._id || null
                this.name = this.defaults.name || null
                this.slug = this.defaults.slug || null
            })
        }
    },
    methods: {
        onSubmit () {
            this.$v.$reset()
            this.$nextTick(() => {
                if (this.$v.$invalid) {
                    this.$notify.error(this.$t('errors.someDataIsMissing'))
                    this.$v.$touch()
                } else {
                    this.$emit('submit', JSON.parse(JSON.stringify(this.$data)))
                }
            })
        },
        onCancel () {
            this.$bvModal.hide('externalAcquirerModal')
        }
    },
    validations: {
        name: {
            required
        },
        slug: {
            required
        }
    }
}
</script>
