<template>
    <div class="container my-5">
        <h1>{{$t('externalAcquirers.heading')}}</h1>
        <p>{{$t('externalAcquirers.subtitle')}}</p>

        <h3 class="mt-5 mb-2">{{$t('externalAcquirers.externalAcquirersTable.title')}}</h3>
        <div class="bg-white shadow p-4 rounded">
            <loader-overlay
                :loading="saving"
            >
                <external-acquirers-data-table
                    :dataset="acquirers"
                    :loading="loading"
                    @edit="onEdit"
                />
            </loader-overlay>
        </div>
        <external-acquirer-modal
            :acquirer="acquirer || {}"
            @acquirer-updated="onAcquirerUpdated"
        />
    </div>
</template>

<script>
import DataViewBase from '../../../../Components/DataViewBase.vue'
import ExternalAcquirersDataTable from './Components/ExternalAcquirersDataTable.vue'
import ExternalAcquirerModal from './Components/ExternalAcquirerModal.vue'

export default {
    name: 'ExternalAcquirersView',
    extends: DataViewBase,
    components: {
        ExternalAcquirersDataTable,
        ExternalAcquirerModal
    },
    data () {
        return {
            acquirer: {},
            acquirers: [],
            loading: false,
            saving: false
        }
    },
    mounted () {
        this.loadUsers()
    },
    methods: {
        loadUsers () {
            this.loading = true
            this.$api.externalAcquirers.read()
                .then((response) => {
                    this.acquirers = response.data
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('dataTable.loadError'))
                }).finally(() => {
                    this.$nextTick(() => {
                        this.loading = false
                    })
                })
        },
        onEdit (acquirer) {
            this.acquirer = acquirer
            this.$bvModal.show('externalAcquirerModal')
        },
        onAcquirerUpdated () {
            this.$bvModal.hide('externalAcquirerModal')
            this.loadUsers()
        }
    }
}
</script>
