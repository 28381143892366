<template>
    <b-modal id="externalAcquirerModal" hide-footer>
        <template #modal-title>
            {{ $t('externalAcquirer.editAcquirer') }}
        </template>

        <loader-overlay
            :loading="saving"
        >
            <external-acquirer-form
                :defaults="acquirer || {}"
                @submit="onSubmit"
            />
        </loader-overlay>
    </b-modal>
</template>

<script>
import LoaderOverlay from '../../../../../Components/LoaderOverlay.vue'
import ExternalAcquirerForm from './ExternalAcquirerForm.vue'

export default {
    name: 'ExternalAcquirerModal',
    props: {
        acquirer: {
            type: Object,
            required: true
        }
    },
    components: {
        LoaderOverlay,
        ExternalAcquirerForm
    },
    data () {
        return {
            saving: false
        }
    },
    methods: {
        onSubmit (acquirer) {
            this.saving = true
            this.$api.externalAcquirers.update(acquirer.id, acquirer)
                .then(() => {
                    this.$notify.success(this.$t('externalAcquirer.brokerSaveSuccess'))
                    this.$emit('acquirer-updated')
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('adminUsers.usersTable.userSaveError'))
                }).finally(() => {
                    this.$nextTick(() => {
                        this.saving = false
                    })
                })
        }
    }
}
</script>
