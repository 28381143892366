<template>
    <data-table
        :data="dataset"
        :loading="loading"
        :buttons="buttons"
        :header="header"
        :responsive="true"
        class="table-sm-font"
        :auto-update="false"
        :lang="lang"
        :paging="true"
        :actions="true"
        @action="onAction"
    />
</template>

<script>
import DataTableBase from '../../../../../Components/DataTableBase.vue'

export default {
    name: 'ExternalAcquirersDataTable',
    extends: DataTableBase,
    computed: {
        header () {
            return [
                {
                    text: this.$t('externalAcquirers.externalAcquirersTable.id'),
                    data: '_id',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('externalAcquirers.externalAcquirersTable.ivanId'),
                    data: 'ivanId',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('externalAcquirers.externalAcquirersTable.name'),
                    data: 'name',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('externalAcquirers.externalAcquirersTable.slug'),
                    data: 'slug',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('externalAcquirers.externalAcquirersTable.visitsCount'),
                    data: 'visitsCount',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('externalAcquirers.externalAcquirersTable.lastVisitAt'),
                    data: 'lastVisitAt',
                    filterable: true,
                    sortable: true,
                    format (value) {
                        return (value) ? new Date(value).toLocaleString() : value
                    }
                }
            ]
        },
        buttons () {
            return [
                {
                    event: 'edit',
                    text: this.$t('dataTable.edit'),
                    variant: 'primary'
                }
            ]
        }
    }
}
</script>
